<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement @click="$emit('details', element)"
                    v-bind:class="{even: element.getId() % 2 == 0, odd: element.getId() % 2 != 0}" v-for="element in elements.getItems()"
                    v-bind:key="element.getId()">
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ element.getId() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <span class="d-block">{{ element.getName() }}</span>
            <span class="d-block">(<span class="font-italic">{{ element.getSku() }}</span>)</span>
            <a :href="element.getImage().url" @click="$event.stopPropagation()" target="_blank" v-if="element.getImage()">
              <i class="icon-attachment mt-2 d-block" ></i>
            </a>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Qty :amount="element.getQty()" :unit="element.getUnit()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Price :amount="element.getPrice()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Date class="d-block text-success" :date="element.getValidFrom()" />
            <Date class="d-block text-warning" :date="element.getValidTo()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Button v-if="canDeleteEnGrossProducts" name="en_gross.edit.buttons.delete" type="delete"  @click.stop="$emit('delete', element.getId())" />
            <span v-else> - </span>
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="elements.isLoaded() ? $t('en_gross.edit.empty_product') : $t('general.loading')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin, AuthorizedActionsMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import Button from "@/components/elements/Button";
import Date from "@/components/elements/Date";

export default {
  name: "EnGrossProductTable",
  mixins: [ScreenSizeMixin, NumbersMixin, AuthorizedActionsMixin],
  emits: ['confirm', 'details', 'delete'],
  components: {Date, Button, Qty, Price, TableElement, TableElementItem, Table},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
          'en_gross.edit.id',
          'en_gross.edit.product',
          'en_gross.edit.qty',
          'en_gross.edit.price',
          'en_gross.edit.validity',
          'en_gross.edit.actions',
      ],
      responsiveHeaders: [
        'en_gross.edit.product',
        'en_gross.edit.qty',
        'en_gross.edit.price',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    }
  },
}
</script>

<style scoped>

</style>
