<template>
  <Modal :dismissible="true" :with-footer="false">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold">{{$t('en_gross.edit.details.id')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{product.getId()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold">{{$t('en_gross.edit.details.sku')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{product.getSku()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold">{{$t('en_gross.edit.details.farmer')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{product.getFarmerName()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1font-weight-semibold">{{$t('en_gross.edit.details.product')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{product.getName()}}
          <a :href="product.getImage().url" @click="$event.stopPropagation()" target="_blank" v-if="product.getImage()">
            <i class="icon-attachment ml-2" ></i>
          </a>
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.qty')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          <Qty element-style="m-0 p-0" :amount="product.getQty()" :unit="product.getUnit()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.min_qty')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          <Qty element-style="m-0 p-0" :amount="product.getMinQty()" :unit="product.getUnit()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.price')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          <Price :amount="product.getPrice()" :currency="product.getCurrency()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.validity')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          <Date :date="product.getValidFrom()" /> - <Date class="text-warning" :date="product.getValidTo()" />
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.diameter')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-light">
         {{formatQty(product.getDiameterFrom(), 'mm')}} - {{formatQty(product.getDiameterTo(), 'mm')}}
        </dd>
        <dt class="col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.packing')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-light">
          {{$t("frontoffice.farmers.offers.en-gross.item.packing_value", {qty: product.getPacking(), unit: product.getUnit()})}}
        </dd>
        <dt class="col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.certificates')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-light">
          {{product.getCertificates() ?? '-'}}
        </dd>
        <dt class="col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.delivery_area')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-light">
          <ul class="pl-3">
            <li v-for="(area) in product.getDeliveryAreas()" :key="area">{{$t("frontoffice.farmers.offers.en-gross.item.delivery."+area)}}</li>
          </ul>
        </dd>
        <dt class="col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.delivery_euro_pallet')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-light">
          {{product.hasDeliveryCooler() ? $t('general.yes') : $t('general.no')}}
        </dd>
        <dt class="col-4 col-md-2 font-weight-light">{{$t('en_gross.edit.details.delivery_cooler')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-light">
          {{product.hasDeliveryEuroPallet() ? $t('general.yes') : $t('general.no')}}
        </dd>
      </dl>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import {NumbersMixin} from "@/mixins/GeneralMixin";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import Date from "@/components/elements/Date";

export default {
  name: "EnGrossProductDetailsModal",
  components: {Date, Qty, Price, Modal},
  mixins: [NumbersMixin],
  emits: ['confirm'],
  props: {
    product: Object,
  },
}
</script>

<style scoped>
.qty {
  text-align: left !important;
}
</style>