<template>
  <ConfirmationModal :with-prefix="false" @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <EnGrossProductDetailsModal v-if="selectedProduct" :product="selectedProduct" @closemodal="selectedProduct=null"/>
  <Card :with-footer="false" :with-header="true" title="en_gross.edit.title">
    <template v-slot:default>
      <EnGrossProductTable @details="showProductDetails" @page="getEnGrossProducts" @delete="confirmDeleteEnGrossProduct" :elements="enGrossProducts" />
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import {ConfirmationModalMixin, LoadingModalMixin} from "@/mixins/ModalMixin";
import LoadableCollection from "@/entities/LoadableCollection";
import Pagination from "@/entities/Pagination";
import {DatesMixin, DownloadMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import EnGrossProductTable from "@/components/engross/backoffice/EnGrossProductTable";
import EnGrossProduct from "@/entities/EnGrossProduct";
import EnGrossProductDetailsModal from "@/components/engross/backoffice/EnGrossProductDetailsModal";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import EmittedEvent from "@/entities/EmittedEvent";

export default {
  name: "EnGrossProductCard",
  components: {ConfirmationModal, EnGrossProductDetailsModal, EnGrossProductTable, Card},
  mixins: [LoadingModalMixin, ScreenSizeMixin, DatesMixin, DownloadMixin, ConfirmationModalMixin],
  emits: ['update', 'refresh'],
  props: {
    farmerId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      enGrossProducts: new LoadableCollection(),
      selectedProduct: null,
    }
  },
  mounted() {
    this.getEnGrossProducts();
  },
  methods: {
    showProductDetails: function (product) {
      this.selectedProduct = product;
    },
    getEnGrossProducts: function (pageNr = 1) {
      let that = this;
      let localProducts = new LoadableCollection();
      let filterParams = {
        page: pageNr,
      };

      this.queueJob();
      this.axios.get(this.$store.state.config.getEnGrossProductsUri(this.farmerId), {params: filterParams}).then(
          response => {
            let content = response.data;
            localProducts.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (product) {
              localProducts.push(new EnGrossProduct(product));
            });

            localProducts.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (localProducts.isLoaded()) {
          that.enGrossProducts.empty();
          that.enGrossProducts.setPagination(localProducts.getPagination());
          localProducts.getItems().forEach(product => that.enGrossProducts.push(product));
          that.enGrossProducts.loaded();
        }
        that.completeJob();
      });
    },
    confirmDeleteEnGrossProduct: function (id) {
      this.askConfirmation(new EmittedEvent("en_gross.edit.confirm.delete", id));
    },
    confirmed: function () {
      switch (this.confirmationType) {
        case "en_gross.edit.confirm.delete":
          this.deleteEnGrossProduct(this.confirmedEvent.getPayload());
          break;
        default:
          break;
      }
      this.closeConfirmation();
    },
    deleteEnGrossProduct: function (id) {
      let that = this;
      this.queueJob();
      this.axios.delete(this.$store.state.config.getEnGrossProductUri(this.farmerId, id))
          .finally(function () {
                that.completeJob();
                that.getEnGrossProducts();
              }
          );
    },
  }
}
</script>

<style scoped>

</style>